body {
  background-color: #070707;
  color: #FFFFFF;
}
.container {
  max-width: 1000px;
  font-size: 32;
  font-family: "League spartan";

}
h2 {
  font-weight: bolder;
}
h1 {
  color: #FFFFFF;
}