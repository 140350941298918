.logon-container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logon-container section.form {
    width: 100%;
    max-width: 350px;
    margin-right: 30px;
}

.logon-container section.form form {
    margin-top: 100px;
}

.logon-container section.form form h1{
    font-size: 32px;
}

.back-link{
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: #41414d;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    transition: opacity 0.2s;
}

.back-link svg{
    margin-right: 8px;
}

.back-link:hover{
    filter: opacity(80%);
}

.fundo-verde{
    background-color: aquamarine;
}
.fundo-texto{
    background-color: rgb(75, 133, 110);
}
.fundo-cinza{
    background-color: rgba(0, 0, 0, 0.267);
}

.text-bg-dark {
    color: #fff!important;
    background-color: RGBA(33,37,41,var(--bs-bg-opacity,1))!important;
}